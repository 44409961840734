<template>
<div>
    <v-dialog v-model="$store.state.dialog" width="1800">
        <v-card flat class="card">
            <v-toolbar outlined flat>
                <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" v-model="valid" :key="update">
                <!-- <pre dir="ltr">
                {{ item }}
                </pre> -->
                <v-container fluid>
                    <v-card outlined>
                        <v-container fluid>

                            <!-- <v-alert color="primary" text>
                                <h3>بيانات التاجر</h3>
                            </v-alert>
                            <v-row>
                                <aAutoComplete @input="getReceivingAreas()" :loading="$global.state.loading" :fieldTitle="true" v-model="item.receivingGovernorateId" :items="$global.state.governorates" item-text="name" item-value="id" title="محافظة الاستلام" :cols="12" :md="3" :required="true" />
                                <aAutoComplete v-model="item.receivingAreaId" :loading="$global.state.loading" :fieldTitle="true" :items="receiving_areas" item-text="name" item-value="id" title="منطقة الاستلام" :cols="12" :md="3" :required="false" />
                            </v-row> -->

                            <!-- <br /> -->
                            <v-alert color="primary" text>
                                <h3>بيانات التاجر والزبون</h3>
                            </v-alert>
                            <v-row>
                                <aAutoComplete @input="selectMerchant()" v-model="merchant" :loading="$global.state.loading" :fieldTitle="true" :items="merchants" item-text="fullName" item-value="id" title="التاجر" :cols="12" :md="3" :required="true" :return-object="true" />
                                <aTextField v-model="item.customerName" title="اسم الزبون" :cols="12" :md="3" :required="false" :fieldTitle="true" />
                                <aPhoneNumber v-model="item.customerPhoneNumber" title="رقم هاتف 1" :cols="12" :md="3" :required="true" :fieldTitle="true" />
                                <aPhoneNumber v-model="item.customerPhoneNumber2" title="رقم هاتف 2" :cols="12" :md="3" :required="false" :fieldTitle="true" />
                                <aAutoComplete @input="getDeliveryAreas()" :loading="$global.state.loading" :fieldTitle="true" v-model="item.deliveryGovernorateId" :items="$global.state.governorates" item-text="name" item-value="id" title="محافظة التوصيل" :cols="12" :md="3" :required="true" />

                                <!-- <aAutoComplete v-model="item.deliveryAreaId" :loading="$global.state.loading" :fieldTitle="true" :items="dlivery_areas" item-text="name" item-value="id" title="منطقة التوصيل" :cols="12" :md="3" :required="false" /> -->
                                <v-col cols="12" md="3">
                                    <h3>منطقة التوصيل</h3>
                                    <v-autocomplete filled outlined hide-details="auto" @input="getDeliveryAreas()" :loading="$global.state.loading" v-model="item.deliveryAreaId" :items="dlivery_areas" item-text="name" item-value="id" placeholder="منطقة التوصيل">
                                        <template v-slot:item="data">                                            
                                            <v-list>
                                                <v-list-item @click="openAddDialog()" v-if="data.item.id == 0">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="primary--text">
                                                            <v-icon class="ml-2" color="primary">fi fi-rr-add</v-icon>
                                                            اضافة منطقة جديدة
                                                        </v-list-item-title>
                                                        
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <aTextField v-model="item.deliveryAddress" title="عنوان التوصيل" :cols="12" :md="3" :fieldTitle="true" />

                            </v-row>

                            <br />
                            <v-alert color="primary" text>
                                <h3>بيانات الشحنة</h3>
                            </v-alert>
                            <v-row>
                                <!-- <pre dir="ltr">
                                    {{ warehouse }}
                                </pre> -->
                                <aAutoComplete v-if="$store.state.user.userType.id == 1 && $store.state.itemDetails.dialogType == 'add'" @input="selectWarehouse()" v-model="warehouse" :loading="$global.state.loading" :fieldTitle="true" :items="warehouses" item-value="id" item-text="name" title="المخزن" :cols="12" :md="3" :required="false" :return-object="true" />
                                <aAutoComplete v-if="$store.state.user.userType.id == 1 && $store.state.itemDetails.dialogType == 'add'" v-model="item.officeId" :loading="$global.state.loading" :fieldTitle="true" :items="offices" item-text="name" item-value="id" title="المكتب" :cols="12" :md="3" :required="true" />
                                <aTextField v-model="item.packageContent" title="المحتويات" :cols="12" :md="3" :fieldTitle="true" />
                                <aTextField v-model="item.packageItemsCount" title="الكمية" :cols="12" :md="3" :fieldTitle="true" />
                                <aTextField v-model="item.deliveryAddress" title="اقرب نقطة دالة" :cols="12" :md="3" :fieldTitle="true" />
                                <aMoneyField v-model="item.packageValue" title="قيمة الشحنة الاجمالية" :cols="12" :md="3" :required="true" suffix="د.ع" :fieldTitle="true" />
                                <!-- <aMoneyField v-if="$store.state.user.userType.id == $global.state.roleType.admin" :key="warehouse + '' + item.deliveryGovernorateId + warehouse.deliveryCost" v-model="item.deliveryCost" title="اجور التوصيل ( للمندوب )" :cols="12" :md="3" suffix="د.ع" :fieldTitle="true" /> -->
                                <aMoneyField :key="item.merchantId + '' + item.deliveryGovernorateId" v-model="item.officeFees" title="اجور مكتب التوصيل" :cols="12" :md="3" :required="true" suffix="د.ع" :fieldTitle="true" />
                                <aMoneyField v-model="item.extraSize" title="حجم إضافي" :cols="12" :md="3" :fieldTitle="true" />
                                <aMoneyField v-model="item.sizeCost" title="تكلفة الحجم" :cols="12" :md="3" suffix="د.ع" :fieldTitle="true" />
                                <!-- <aTextField :value="calculatedPackageCost" title="تكلفة الشحنة" :cols="12" :md="3" :required="true" suffix="د.ع" :disabled="true" :fieldTitle="true" /> -->
                                <aTextField v-model="item.no" title="رقم الشحنة" :cols="12" :md="3" :required="true" :fieldTitle="true" :disabled="$store.state.itemDetails.dialogType != 'add'" />
                                <aTextField v-model="item.note" title="ملاحظات" :cols="12" :md="3" :fieldTitle="true" />

                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :disabled="!valid" :loading="$global.state.loading" x-large color="primary" class="btn">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
    <AreaDialog/>
</div>
</template>

<script>
export default {

    components: {
        AreaDialog: () => import("../area/dialog.vue"),
    },

    data() {
        return {
            item: {},
            valid: false,
            loading: false,
            merchant: "",
            warehouse: "",
            receiving_areas: [],
            dlivery_areas: [],
            merchants: [],
            delegaters: [],
            warehouses: [],
            offices: [],
            update: 0
        }
    },

    mounted() {
        setTimeout(() => {
            this.$eventBus.$on(`area`, () => {
                this.getDeliveryAreas()
            })
        }, 100);

        this.$eventBus.$on('fill-fields', () => {
            this.reset()
            this.getMerchants()
            this.getDeliveryAreas()
            this.getReceivingAreas()
            this.getWarehouses()
            this.getOffices()

            this.item = this.$store.state.itemDetails

            this.item.merchantId = this.$store.state.itemDetails.merchant.id
            this.merchant = this.$store.state.itemDetails.merchant
            this.warehouse = this.$store.state.itemDetails.warehouse
            this.item.officeFees = this.$store.state.itemDetails.officeFees
            this.item.officeId = this.$store.state.itemDetails.office.id
            this.item.deliveryGovernorateId = this.$store.state.itemDetails.deliveryGovernorate.id
            this.item.deliveryAreaId = this.$store.state.itemDetails.deliveryArea.id

        })

        this.$eventBus.$on('fetch-data', () => {
            this.reset()
            this.getMerchants()
            this.getDeliveryAreas()
            this.getReceivingAreas()
            this.getWarehouses()
            this.getOffices()
            // this.getDelegaters()
        })

    },
    computed: {
        calculatedPackageCost() {
            const packageValue = parseFloat(this.item.packageValue) || 0;
            const officeFees = parseFloat(this.item.officeFees) || 0;
            const sizeCost = parseFloat(this.item.sizeCost) || 0;

            const calculatedCost = (packageValue + officeFees + sizeCost).toFixed(2);
            console.log('Calculated Package Cost:', calculatedCost);

            return this.$service.formatCurrency(calculatedCost);
        }
    },

    watch: {
        item: {
            deep: true,
            handler() {
                // Update form validity whenever a field changes
                this.checkFormValidity();
            },
        },
        calculatedPackageCost: {
            immediate: true,
            handler(newValue) {
                console.log('Watch triggered with new value:', newValue);
                this.item.packageCost = newValue;
            }
        }
    },

    methods: {

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails4', item)
            this.$store.commit('setDialog4')
            this.$eventBus.$emit('fetch-data')
        },

        selectMerchant() {
            // this.item.deliveryCost = this.merchant.deliveryCost
            if (this.item.deliveryGovernorateId == 1) {
                this.item.officeFees = this.merchant.deliveryCost;
            } else {
                this.item.officeFees = this.merchant.deliveryCostForGovernates;
            }
            this.getDeliveryCost()
            this.item.merchantId = this.merchant.id
            this.item.receivingGovernorateId = this.merchant.governorate.id
            this.item.receivingAreaId = this.merchant.area.id
        },

        selectWarehouse() {
            console.log('this.warehouse')
            if (this.warehouse != undefined) {
                console.log(this.warehouse)
                console.log(this.warehouse.deliveryCost)
                this.item.warehouseId = this.warehouse.id
                // this.item.deliveryGovernorateId = this.warehouse.governorate != undefined ? this.warehouse.governorate.id : ''
                this.item.deliveryCost = this.warehouse.deliveryCost
                this.getDeliveryCost()
            }
        },

        checkFormValidity() {
            // List of required fields
            const requiredFields = [
                'no',
                'merchantId',
                'customerPhoneNumber',
                'receivingGovernorateId',
                'deliveryGovernorateId',
                'packageValue',
                'packageCost',
            ];

            // Check if all required fields are filled
            this.valid = requiredFields.every(field => !!this.item[field]);
        },

        getDeliveryCost() {
            // if (this.item.deliveryGovernorateId == 1) {
            //     this.item.deliveryCost = 3000;
            // } else {
            //     this.item.deliveryCost = 5000;
            // }
            const packageValue = parseFloat(this.item.packageValue) || 0;
            const officeFees = parseFloat(this.item.officeFees) || 0;
            const sizeCost = parseFloat(this.item.sizeCost) || 0;

            const calculatedCost = (packageValue + officeFees + sizeCost).toFixed(2);
            console.log('calculatedCost')
            console.log(calculatedCost)
            this.item.packageCost = calculatedCost
        },

        async getOffices() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Office`)
                this.offices = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        reset() {
            this.item = {
                "no": "",
                "note": "",
                "merchantId": "",
                "customerName": "",
                "customerPhoneNumber": "",
                "customerPhoneNumber2": "",
                "receivingGovernorateId": 1,
                "receivingAreaId": "",
                "deliveryGovernorateId": 1,
                "deliveryAreaId": "",
                "deliveryAddress": "",
                "packageContent": "",
                "packageItemsCount": "",
                "packageValue": "",
                "packageCost": "",
                "deliveryCost": "",
                "officeFees": "",
                "extraSize": "",
                "sizeCost": "",
                "delegaterId": "",
                "warehouseId": this.$store.state.user.warehouse != null ? this.$store.state.user.warehouse.id : '',
                "statusId": 2,
                // "officeId": "",
            }
            this.merchant = ''
            this.warehouse = ""
            this.update++
        },

        submit() {
            if (this.valid) {
                if (this.$store.state.itemDetails.dialogType == 'add') {
                    this.addItem()
                } else {
                    this.editItem()
                }
            } else {
                this.$service.faild('يجب ملئ جميع الحقول المطلوبة!')
            }
        },

        async getMerchants() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`User?PageIndex=1&PageSize=100&UserTypeId=${this.$global.state.roleType.merchant}`)
                this.merchants = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        async getWarehouses() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Warehouse?PageIndex=1&PageSize=100&Type=1`)
                this.warehouses = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        // async getDelegater() {
        //     this.$global.state.loading = true
        //     try {
        //         const res = await this.$http.get(`User?PageIndex=1&PageSize=100&UserTypeId=${this.$global.state.roleType.delivery}`)
        //         this.delegaters = res.data.result
        //     } catch (err) {
        //         console.log(err)
        //         this.$service.faild(err.response.data.message)
        //     } finally {
        //         this.$global.state.loading = false
        //     }
        // },

        async getReceivingAreas() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Area?PageIndex=1&PageSize=10000&GovernorateId=${this.item.receivingGovernorateId}`)
                this.receiving_areas = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        async getDeliveryAreas() {

            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Area?PageIndex=1&PageSize=10000&GovernorateId=${this.item.deliveryGovernorateId}`)
                this.dlivery_areas = res.data.result
                this.dlivery_areas.unshift({
                    id: 0,
                    name: 'اضافة منطقة'
                })
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
            this.selectMerchant()
            this.getDeliveryCost()
        },

        addItem() {
            this.$global.state.loading = true
            this.item.statusId = 1;
            this.$http.post(`Order`, this.item).then(res => {
                this.$service.success('تم الإضافة بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
                this.reset()
                // this.close()
            }).catch(err => {
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        editItem() {
            this.$global.state.loading = true
            this.$http.put(`Order/${this.item.id}`, this.item).then(res => {
                this.close()
                this.$service.success('تم التعديل بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
            }).catch(err => {
                // this.$service.faild(err.response.data.message)
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        close() {
            this.$eventBus.$emit(`${this.$route.path}`)
            this.$store.commit('setDialog')
            this.reset()
            this.resetValidation()
            this.$store.commit('setItemDetails', {})
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>
